import React from "react"
import Layout from "../../components/layout-ages"
import { Link } from "gatsby"

export default () => (
  <Layout title="4 Year Old">
    <h2>4 Year Old</h2>
    <div className="clear-block">
      <div id="node-15" className="node">
        <div className="content clear-block">
          <p><img src="/images/054-mitchell.jpg" className="float-left" width="230"
                  height="307" alt=""/> Where three year olds are eager to learn, they still get bored easily, they need to
            work with for short time periods and as frequently as possible. A four year old is not only eager to learn,
            but ready to concentrate on the material being presented. They can sit for 15 minutes or more and play a
            piece through more than once. If they are just starting, you will find that your student will move through
            the “<Link to="/books/Beginning-Note-Reading">Note Reading for Children</Link>” in about six months. If they
            started learning when they were three, they will soon be ready to move on to the “<Link
              to="/books/Beginning-Timing">Timing for Children</Link>”. However, keep in mind that each individual is
            unique, and will move at their own pace. You might start a four year old that is less mature, and it will be
            more like working with a three year old. On the other hand, the child might be more mature and learn more
            like a five year old. On the average, they are able to handle counting to four without difficulty and
            therefore can be taught the basic principles of timing. They will love playing songs in the "<Link
              to="/books/The-Fun-Song-Book">Fun Book–One</Link>” and the “<Link to="/books/Christmas-Song-Book">Christmas
              Song Book</Link>”. Their timing will not be perfect, but they are able to work on correcting their
            mistakes.<br/>
            Remember, they are young, so keep your lessons short and fun. Use the often, "<Link
              to="/flash-cards/Animal-Note-Standard-Note-Flash-Card-Set-Student-Size">Animal Note/Standard Note Flash
              Card Set-Grand Staff</Link>"word clues with both sides of each card.</p>
          <h3>Testimonial</h3>
          <p><i>"I ordered materials from you last week. The material is for my daughter, Eva, 4 and myself. I have
            never learned how to play the piano. My daughter has a lot of interest in learning. I couldn't find anyone
            who would take her on as a student so young, so I went on the web to find materials so I could teach her, or
            really, so we could learn together. Although this is just our 3rd day in learning, she is doing well. She
            loves the animals recognize and is enjoying it.( And it is easy enough for me, that I can figure out what's
            going on! I am an elementary education teacher; I had to take music for the elementary child, so I am a
            little familiar with quarter, half and whole notes). I don't push her to do a set time, because she is just
            4. She loves showing my husband, her daddy what she has learning while he was at work."<br/>
            - Beth</i></p>
        </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>
  </Layout>
)
